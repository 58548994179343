import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import { BrainParams } from "@/brain-search/internal/BrainSearchPage"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import BackIcon from "@/core/ui/iconsax/linear/arrow-left.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { generatePath, useHistory, useParams } from "react-router-dom"

interface Props extends TestIDProps {
  title?: string
}

function BrainSearchPageHeader({ title = "Untitled" }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const queryParams = useQueryParams<BrainSearchParams>()
  const params = useParams<BrainParams>()
  const history = useHistory()
  const copyToClipboard = useCopyToClipboard()

  return (
    <div className={classes.header}>
      <div className={classes.lhsHeader}>
        {!params.shareId && (
          <DiscoIconButton onClick={handleBack} width={32} height={32}>
            <BackIcon className={classes.backIcon} />
          </DiscoIconButton>
        )}

        <DiscoText variant={"body-sm-500"} color={"text.primary"}>
          {title}
        </DiscoText>
      </div>

      <div className={classes.rhsHeader}>
        <DiscoIconButton
          onClick={copyLink}
          tooltip={"Copy Link"}
          className={classes.copyButton}
        >
          <DiscoIcon
            icon={"link"}
            color={theme.palette.text.primary}
            width={16}
            height={16}
          />
        </DiscoIconButton>
      </div>
    </div>
  )

  function handleBack() {
    history.push(generatePath(ROUTE_NAMES.BRAIN.ROOT))
  }

  function copyLink() {
    const shareId = queryParams.searchId || params.searchId || params.shareId
    if (!shareId) return

    const link = `${window.location.origin}${generatePath(ROUTE_NAMES.BRAIN.SHARE, {
      shareId,
    })}`
    copyToClipboard(link)
  }
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.raise1,
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    minHeight: theme.mixins.toolbar.minHeight,
    borderBottom: theme.palette.constants.borderSmall,
  },
  lhsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  rhsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  backIcon: {
    height: "16px",
    width: "16px",
  },
  copyButton: {
    padding: theme.spacing(0.5),
  },
}))

export default BrainSearchPageHeader
