import BotModelSwitcher from "@/brain-search/internal/BotModelSwitcher"
import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import BrainSearchSidebarHeader from "@/brain-search/internal/BrainSearchSidebarHeader"
import BrainSearchSidebarList from "@/brain-search/internal/BrainSearchSidebarList"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoIcon, DiscoSideBar, DiscoSideBarItem, DiscoSideBarProps } from "@disco-ui"
import { useQueryParams } from "@utils/url/urlUtils"

export default function BrainSearchSidebar(props: Partial<DiscoSideBarProps>) {
  const classes = useStyles()
  const { searchId } = useQueryParams<BrainSearchParams>()

  return (
    <DiscoSideBar
      {...props}
      fullHeight
      data-testid={"BrainSearchSidebar"}
      header={<BrainSearchSidebarHeader />}
      items={
        <ScrollShadowContainer classes={{ scrollContainer: classes.itemsContainer }}>
          {/* Just here temporarily */}
          <div className={classes.switcherContainer}>
            <BotModelSwitcher />
          </div>

          {/* New Conversation */}
          <DiscoSideBarItem
            name={"New Conversation"}
            to={ROUTE_NAMES.BRAIN.ROOT}
            leftIcon={<DiscoIcon icon={"search"} />}
            selected={isNewSearchActive()}
          />

          {/* Conversations */}
          <DiscoSideBarItem
            name={"Conversation History"}
            indentNestedItems
            forceExpand
            leftIcon={<DiscoIcon icon={"chat-alt"} />}
            classes={{ content: classes.content }}
          >
            <BrainSearchSidebarList />
          </DiscoSideBarItem>
        </ScrollShadowContainer>
      }
    />
  )

  function isNewSearchActive() {
    if (searchId) return false
    if (location.pathname === ROUTE_NAMES.BRAIN.ROOT) return true
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  switcherContainer: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  itemsContainer: {
    flex: "auto",
    display: "inline-flex",
    flexFlow: "column",
    flexShrink: 0,
    minHeight: "100%",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1.5),
  },
  content: {
    cursor: "default",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      "& svg, & p": {
        color: theme.palette.groovy.neutral[500],
      },
    },
  },
}))
