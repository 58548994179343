import {
  BotModelSwitcherMutation,
  UpdateBotSettingsInput,
} from "@/brain-search/internal/__generated__/BotModelSwitcherMutation.graphql"
import { BotModelSwitcherQuery } from "@/brain-search/internal/__generated__/BotModelSwitcherQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoSelect, DiscoSpinner } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

function BotModelSwitcher() {
  const activeOrganization = useActiveOrganization()!

  const { node } = useLazyLoadQuery<BotModelSwitcherQuery>(
    graphql`
      query BotModelSwitcherQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            id
            __typename
            brainSearchBot {
              id
              model
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    { fetchPolicy: "network-only" }
  )
  const organization = Relay.narrowNodeType(node, "Organization")

  const form = useFormStore<BotModelSwitcherMutation, UpdateBotSettingsInput>(
    graphql`
      mutation BotModelSwitcherMutation($input: UpdateBotSettingsInput!) {
        response: updateBotSettings(input: $input) {
          node {
            id
            model
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      botId: organization?.brainSearchBot?.id || "",
      model: organization?.brainSearchBot?.model,
    }
  )

  // Update model after selecting a bot model
  useEffect(() => {
    if (!form.changedState.model) return

    handleUpdateModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.changedState.model])

  type LLModel =
    | "gpt-4o-mini"
    | "gpt-4o"
    | "gemini-2.0-flash"
    | "gemini-1.5-pro"
    | "grok-2"
    | "sonar-reasoning-pro"

  const models: LLModel[] = [
    "gpt-4o-mini",
    "gpt-4o",
    "gemini-2.0-flash",
    "gemini-1.5-pro",
    "grok-2",
    "sonar-reasoning-pro",
  ]

  return (
    <DiscoSelect
      autoComplete={false}
      options={models.map((model) => ({
        value: model,
        title: getNameByModel(model),
      }))}
      value={form.state.model}
      onChange={(v) => {
        form.state.model = v
      }}
      disableClearable
      disabled={form.isSubmitting}
    />
  )

  async function handleUpdateModel() {
    const { didSave } = await form.submit({
      botId: form.state.botId,
      model: form.state.model,
    })
    if (!didSave) return

    form.resetInitialState()
    displaySuccessToast({
      message: `Updated bot model to: ${getNameByModel(form.state.model as LLModel)}`,
    })
  }

  function getNameByModel(model: LLModel) {
    const names: Record<LLModel, string> = {
      "gpt-4o-mini": "OpenAI - GPT-4o Mini",
      "gpt-4o": "OpenAI - GPT-4o",
      "gemini-2.0-flash": "Google - Gemini 2.0 Flash",
      "gemini-1.5-pro": "Google - Gemini 1.5 Pro",
      "grok-2": "xAI - Grok 2",
      "sonar-reasoning-pro": "Perplexity - Sonar Reasoning Pro",
    }
    return names[model]
  }
}

export default Relay.withSkeleton({
  component: observer(BotModelSwitcher),
  skeleton: () => <DiscoSpinner />,
})
