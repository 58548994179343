/**
 * @generated SignedSource<<cb4251d5a077ada4186e8d118bc4078a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginSource = "login" | "join_community" | "registration" | "sign_up" | "%future added value";
export type CompleteSSOLoginInput = {
  token: string;
  loginSource: LoginSource;
  timezone?: string | null;
  codeVerifier?: string | null;
};
export type useCompleteSSORedirectLoginMutation$variables = {
  input: CompleteSSOLoginInput;
};
export type useCompleteSSORedirectLoginMutation$data = {
  readonly completeSSOLogin: {
    readonly redirectOverride: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useCompleteSSORedirectLoginMutation = {
  variables: useCompleteSSORedirectLoginMutation$variables;
  response: useCompleteSSORedirectLoginMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompleteSSOLoginResponse",
    "kind": "LinkedField",
    "name": "completeSSOLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectOverride",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompleteSSORedirectLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompleteSSORedirectLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aeb147d291fa54d744b520353b7c782c",
    "id": null,
    "metadata": {},
    "name": "useCompleteSSORedirectLoginMutation",
    "operationKind": "mutation",
    "text": "mutation useCompleteSSORedirectLoginMutation(\n  $input: CompleteSSOLoginInput!\n) {\n  completeSSOLogin(input: $input) {\n    redirectOverride\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "154d549d31a6530c15f231af36f6ad81";

export default node;
